import { Typography, getColor } from '@/shared';
import { ColorContext } from '@/shared/providers/ColorContext';
import { useBreakpoints } from '@/shared/providers/Device';
import { QuoteSectionStoryblok } from '@/shared/types/storyblok/storyblok-types';
import clsx from 'clsx';
import { useContext, useMemo } from 'react';
import Container from '../Container/Container';
import { SBImage } from '../ImageService/SBImage';
import { Mindentity } from '../Mindentity/Mindentity';
import styles from './QuoteSection.module.scss';

const QuoteSection = (props: QuoteSectionStoryblok) => {
    const hexColor = useContext(ColorContext);
    const { isXXSmall, isMedium, isLarge, isExtraSmall, isExtraLarge } = useBreakpoints();
    const isMobile = useMemo(() => !isExtraLarge && !isLarge, [isExtraLarge, isLarge]);
    const imgDimension = isMobile ? 128 : 192;
    const { mind, fullname, image, mindentity, company, quote, role, isDark, textAlignRight } = props.blok;
    const pageColor = getColor(hexColor, isDark);

    const joinedRole = (role: string | string[]) => (Array.isArray(role) ? role.join(' & ') : role);

    const quotePerson = useMemo(() => {
        const { fullname: nameMind, role: roleMind, portrait: imageMind, mind_color: mind_color } = mind?.content || {};
        return {
            quote: quote,
            fullname: nameMind || fullname,
            role: joinedRole(roleMind) || joinedRole(role),
            image: imageMind || image,
            mindIdentity: mindentity,
            company: company,
            mind_color: mind_color,
        };
    }, [mind, fullname, role, image, mindentity, company, quote]);

    const cutpoint = useMemo(() => {
        return isExtraLarge || isLarge
            ? 25
            : isMedium
            ? 20
            : isExtraSmall
            ? 19
            : isXXSmall
            ? 10
            : quotePerson.fullname.length;
    }, [isExtraLarge, isLarge, isMedium, isExtraSmall, isXXSmall, quotePerson.fullname.length]);

    const longWords = quotePerson.fullname.length > cutpoint;
    const cutPoint = fullname.indexOf(' ', cutpoint);
    const adjustedCutPoint = cutPoint === -1 ? quotePerson?.fullname.lastIndexOf(' ', cutpoint) : cutPoint;

    const getAdjustedName = () =>
        longWords ? quotePerson?.fullname.slice(0, adjustedCutPoint) : quotePerson?.fullname;
    const getRemainingName = () => (longWords ? quotePerson?.fullname.slice(adjustedCutPoint + 1) : '');
    return (
        <Container
            blok={props.blok}
            code="large"
            className={{
                wrap: styles.wrapper,
                root: clsx(styles.root, {
                    [styles.dark]: isDark,
                    [styles.textAlignRight]: textAlignRight,
                }),
            }}
        >
            <div className={styles.quoteWrapper}>
                <Typography.Quote darkTheme={isDark} className={styles.quote}>
                    {quote}
                </Typography.Quote>
            </div>
            {quotePerson?.fullname && (
                <div className={styles.content}>
                    <div className={styles.images}>
                        {quotePerson?.image?.filename && (
                            <div  style={{
                                minHeight: imgDimension,
                                minWidth: imgDimension,
                            }}>
                                <SBImage
                                    image={quotePerson?.image}
                                    width={imgDimension}
                                    height={imgDimension}
                                    fit="contain"
                                    className={styles.image}
                                />
                            </div>
                        )}
                        <div
                            style={{
                                marginLeft: '16px',
                                minHeight: imgDimension,
                                minWidth: imgDimension,
                            }}
                            className={styles.mindentity}
                        >
                            {quotePerson && (
                                <Mindentity
                                    name={quotePerson.fullname}
                                    color={quotePerson.mind_color?.color || ''}
                                    isDark={isDark}
                                    hover={false}
                                >
                                    <picture />
                                </Mindentity>
                            )}
                        </div>
                    </div>
                    <div className={styles.subContent}>
                        <div>
                            <div className={styles.nameWrapper}>
                                <span className={clsx(styles.name, { [styles.shortName]: longWords })}>
                                    {getAdjustedName()}
                                </span>
                                <div style={{ borderColor: pageColor }} className={styles.line} />
                            </div>
                            {getRemainingName() !== '' && (
                                <div className={styles.remainingName}>{getRemainingName()}</div>
                            )}
                        </div>

                        <div className={styles.subInfo}>
                            <Typography.Quote className={clsx(styles.role)} darkTheme={isDark}>
                                {quotePerson?.role}
                                {company && quotePerson?.role && ', '}
                                {company}
                            </Typography.Quote>
                        </div>
                    </div>
                </div>
            )}
        </Container>
    );
};

export default QuoteSection;
